<template>
  <report-layout title="Performance and uptime" class="is-reversed">
    <p slot="desc">
      We monitor your website every minute of every day, so we can quickly
      detect issues and identify significant changes in your sites performance.
    </p>
    <p slot="desc">
      In {{ $report.timeframe }} your site achieved
      <strong>{{ $report.monitorUptime }} uptime</strong> and had an average
      response time of <strong>{{ $report.monitorResponseTime }}</strong
      >.
    </p>

    <template slot="default">
      <!-- Uptime -->
      <div class="column is-12-mobile is-4-tablet is-6-desktop is-4-widescreen">
        <div class="box has-padding-100 has-background-success has-text-white">
          <p class="is-size-5">
            <strong>{{ $report.monitorUptime }} Uptime</strong>
          </p>
          <p class="is-size-7">In {{ $report.timeframe }}</p>
        </div>
      </div>
      <!-- Downtime -->
      <div class="column is-12-mobile is-4-tablet is-6-desktop is-4-widescreen">
        <div class="box has-padding-100 has-background-danger has-text-white">
          <p class="is-size-5">
            <strong>{{ $report.monitorDowntime }} Downtime</strong>
          </p>
          <p class="is-size-7">In {{ $report.timeframe }}</p>
        </div>
      </div>
      <!-- Average response time -->
      <div class="column is-12-mobile is-4-tablet is-6-desktop is-4-widescreen">
        <div class="box has-padding-100 has-background-info has-text-white">
          <p class="is-size-5">Average response time</p>
          <p class="is-size-7">
            <strong>{{ $report.monitorResponseTime }}</strong>
          </p>
        </div>
      </div>
      <!-- Response time chart -->
      <div class="column is-12 has-padding-top-150">
        <response-time-chart :monitor="$report.monitor" period="month" />
      </div>
    </template>
  </report-layout>
</template>

<script>
export default {
  name: "SiteReportMonitoring",
  inject: ["$reportProvider"],
  components: {
    "report-layout": () => import("./_layout"),
    "response-time-chart": () =>
      import("@shared/sites/monitoring/_responseTimeChart")
  },
  computed: {
    $report() {
      return this.$reportProvider();
    }
  }
};
</script>
